.navbar-nav, .navbar-toggler
  margin-left: auto

@media (max-width: 767px)
  .navbar-nav
    font-size: 28px
    line-height: 1.25
    padding-top: 1rem
    padding-bottom: 1rem

.navbar-nav .nav-link span, .navbar-nav .nav-link
  color: rgba(51, 51, 51)

.admin-area
  background-color: #f8d7da

.dropdown-menu.right-aligned
  right: 0
  left: auto !important

@media (max-width: 575px)
  .dropdown-menu.right-aligned-on-small-screens
    right: 0
    left: auto !important

.navbar-avatar
  object-fit: cover
  width: 32px
  height: 32px
  max-height: inherit !important
  border-radius: 50%

.scrollable-dropdown
  max-height: 475px
  overflow-y: auto

@media (max-width: 600px)
  .autosave-message
    display: none !important
