
.h-100
  height: 100%


.muted-link
  color: hsl(0, 0%, 71%)
  &:hover
    color: hsl(0, 0%, 48%)
    text-decoration: underline

// this is the default class assigned to errors by django forms
.errorlist
  color: var(--danger)


img.socialicon
  padding-right: .5em
  max-width: 24px
  max-height: 24px

.hstack
  display: flex
  align-items: center
  gap: 4px

.hidden
  display: none !important

