

#profile-picture
  display: flex
  justify-content: center
  height: 128px
  img.avatar
    object-fit: cover
    width: 128px
    height: 128px
    border-radius: 50%


#profile-upload-holder
  padding-top: .5em
  display: none

  input[type="file"]
    display: none


#profile-picture:hover #profile-upload-holder
  display: block
