
// variables used to style selected plans
$selected-color: var(--primary)
$selected-border-size: 3px

.upgrade-features
  font-size: 0.875em

.upgrade-features
  li
    display: flex
    .pg-icon
      margin-right: .5rem
    .upgrade-feature
      width: 100%

.upgrade-features
  .included::before, li:not(.excluded)::before
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='green' class='bi bi-check-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z'/%3E%3C/svg%3E ")
    min-width: 2rem

.upgrade-features
  .excluded::before
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23adb5bd' class='bi bi-x-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
    min-width: 2rem

.upgrade-features .excluded
  color: #adb5bd

#plan-selector
  .card
    min-width: 280px
    max-width: 380px

#plan-selector .card .card-header
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 7rem

#plan-selector
  li
    margin-bottom: 0.5rem

#plan-selector .card-body
  padding-top: var(--bs-card-spacer-y)
  padding-bottom: var(--bs-card-spacer-y)
  padding-right: var(--bs-card-spacer-x)
  padding-left: var(--bs-card-spacer-x)

.pricing-footer
  height: 3rem

#plan-selector .or-contact-sales
  font-size: 0.875rem
  color: var(--primary-800)
  text-decoration: underline

.price-and-most-important-info
  display: flex
  flex-direction: column
  justify-content: flex-start
  min-height: 9rem

#subscription-details-table
  .subscription-detail
    display: flex
    flex-direction: row
    align-items: flex-start
    margin-top: .5rem
    margin-bottom: .5rem
    .subscription-detail-label
      width: 16rem
      text-align: left
      margin-right: 1rem
    .subscription-detail-value
      width: 100%
    .subdetail
      font-size: .8rem
      margin-top: .2rem

#plan-selector
  .plan-pegasus
    height: 100% // fill the whole column
    // prevent shifting when border is added
    border: $selected-border-size solid transparent
    border-radius: 8px
    cursor: pointer
    padding: 1rem

    &.is-selected
      border: $selected-border-size solid $selected-color

    .plan-summary
      display: flex
      flex-direction: row
      align-items: center
